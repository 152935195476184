import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.PAYMENTS,
  state: states.PAYMENTS_CONFIRMATION_REQUIRED,
  frozenConfig: {
    links: {
      accounts: [
        {
          id: 'id 1',
          number: 12123312,
          type: 'checking_account',
          currency: 'CLP',
          balance: {
            limit: 1023456,
            available: 23456,
          },
        },
        {
          id: 'id 2',
          number: 12121619,
          type: 'sight_account',
          currency: 'CLP',
          balance: {
            limit: 4132971,
            available: 4132971,
          },
        },
      ],
    },
  },
};
